import axios from "@/plugins/axios";

export default {
  getAddressListByType(addressType) {
    addressType = addressType || "drive";
    return axios
      .get("/ebsn/api/address/list", {
        params: { address_type: addressType }
      })
      .then(data => {
        return data.data.data.addresses;
      });
  },
  getAddresById(addressId) {
    // addressType = addressType || "drive";
    return axios
      .get("/ebsn/api/address/view", {
        params: { address_id: addressId }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getAddressByDeliveryService(
    deliveryServiceId,
    latitude,
    longitude,
    distance = global.config.proximityDefaultDistance
  ) {
    return axios
      .get("/ebsn/api/address/delivery-service-list", {
        params: {
          delivery_service_id: deliveryServiceId,
          latitude: latitude,
          longitude: longitude,
          distance: distance
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  isValidAddress(address) {
    return axios.post("/ebsn/api/address/check", address).then(data => {
      if (data.data.response.status == 0) {
        return data.data.data;
      } else return false;
    });
  },
  getHomeDeliveryList(homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/homedelivery-list-simple", {
        params: { home_delivery_only: homeDeliveryOnly }
      })
      .then(data => {
        return data.data.data;
      });
  },
  findWarehouse(parameters) {
    return axios
      .get("/ebsn/api/warehouse-locator/search", {
        params: parameters
      })
      .then(data => {
        return data.data.data;
      });
  }
};
